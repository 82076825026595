// =======
// Objects
// =======
.icon {
  > svg {
    display: inline-block;
    height: 1em;
    vertical-align: middle;
    width: 1em;

    path {
      fill: color("japanese-laurel");
    }
  }
}

.icon--tag,
.icon--tags {
  margin-inline-end: 0.25em;
}

.icon--title {
  > svg {
    height: 1.25em;
    width: 1.25em;
    display: inline-block;
    vertical-align: middle;

    @include mappy-bp(medium) {
      height: 1.5em;
      width: 1.5em;
    }

    path {
      fill: color("mineshaft");
    }
  }
}

.emoji {
  font-size: 1.294rem;
  line-height: 1;
  font-style: normal;
  display: inline-block;
  vertical-align: baseline;
  font-family: "Apple Color Emoji", "Segoe UI Emoji";
}

_:-ms-lang(x),
.emoji {
  vertical-align: top;
}

span[role="img"][aria-label],
span[role="img"][aria-label] {
  position: relative;
}

span[role="img"][aria-label]:focus::after,
span[role="img"][aria-label]:hover::after {
  font-size: 50%;
  font-family: $base-font;
  font-weight: 400;
  position: absolute;
  display: block;
  line-height: 1.2;
  content: attr(aria-label);
  z-index: 1;
  bottom: 2.5em;
  left: 0;
  background: color("english-walnut");
  color: color("white");
  padding: 0.25em;
  border: 1px solid color("white");
  box-shadow: 2px 2px 4px color("english-walnut");
}

@media print {
  span[role="img"][aria-label]::after {
    content: "(" attr(aria-label) ")";
  }
}

.kaomoji {
  white-space: nowrap;
  font-family: Arial, sans-serif;
}

.post-meta {
  font-size: 75%;
  color: lighten(color("tuna"), 20%);
}

// Night mode toggle
.blend-checkbox {
  opacity: 0;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
}

@supports (mix-blend-mode: difference) {
  .blend-toggle {
    position: fixed;
    height: 3rem;
    width: 3rem;
    left: 1rem;
    bottom: 1rem;
    border-radius: 50%;
    background-image: url("/assets/images/sun.png");
    background-position: center;
    background-size: cover;
    z-index: 1;
    opacity: 0.7;

    @include mappy-bp(x-large) {
      left: calc(50% - 24rem);
    }
  }

  .blender {
    position: fixed;
    background-color: color("sugarcane");
    mix-blend-mode: difference;
    pointer-events: none;

    @include mappy-bp(max x-large) {
      opacity: 0;
      height: 100vh;
      width: 100vw;
      transition: opacity 0.5s ease;
    }

    @include mappy-bp(x-large) {
      height: 3rem;
      width: 3rem;
      left: 1rem;
      bottom: 1rem;
      border-radius: 50%;
      left: calc(50% - 24rem);
      transition: transform 0.7s ease-out;
    }
  }

  .blender.active {
    @include mappy-bp(max x-large) {
      opacity: 1;
    }

    @include mappy-bp(x-large) {
      transform: scale(100);
    }
  }

  .blender.active ~ .blend-toggle {
    background-image: url("/assets/images/moon.png");
    isolation: isolate;
  }

  img,
  .external-url::before,
  .emoji {
    isolation: isolate;
  }

  .blend-checkbox:focus ~ label {
    outline: 5px auto -webkit-focus-ring-color;
  }
}
