// =====
// Tools
// =====
// Mixins
@mixin cf {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin center {
  float: none;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

@mixin image-replacement {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin z-index($layer, $position: relative) {
  position: $position;
  z-index: layer-index($layer);
}

// Functions
@function color($color, $map: $colors) {
  @return map-get($colors, $color);
}

@function layer-index($layer) {
  @return index($layers, $layer);
}

@function text-color($color) {
  @if (lightness($color) > 49) {
    @return color(txt-dark); // Lighter background, return dark color
  } @else {
    @return color(txt-light); // Darker background, return light color
  }
}
