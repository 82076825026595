// ========
// Settings
// ========
// Breakpoints
@import 'lib/mappy-bp';
$breakpoints: (
  small:    575px,
  medium:   720px,
  large:    820px,
  x-large:  960px
);

// Responsive typography
$min-width: 320;
$max-width: 960;
$min-font: 18;
$max-font: 22;

:root {
  font-size: #{$min-font}px;
}

@media (min-width: #{$min-width}px) and (max-width: #{$max-width}px) {
  :root {
    font-size: calc( #{$min-font}px + (#{$max-font} - #{$min-font}) * ( (100vw - #{$min-width}px) / ( #{$max-width} - #{$min-width})));
  }
}

@media (min-width: #{$max-width}px) {
  :root {
    font-size: #{$max-font}px;
  }
}

// Fonts
@charset 'utf-8';

@font-face {
  font-family: 'Magnetic Pro Black';
  src: url('/assets/fonts/magnetic-pro-black.woff2') format('woff2'),
       url('/assets/fonts/magnetic-pro-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnetic Pro';
  src: url('/assets/fonts/magnetic-pro-light.woff2') format('woff2'),
       url('/assets/fonts/magnetic-pro-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnetic Pro';
  src: url('/assets/fonts/magnetic-pro-medium.woff2') format('woff2'),
       url('/assets/fonts/magnetic-pro-medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Magnetic Pro';
  src: url('/assets/fonts/magnetic-pro-light-italic.woff2') format('woff2'),
       url('/assets/fonts/magnetic-pro-light-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Magnetic Pro Thin';
  src: url('/assets/fonts/magnetic-pro-thin-italic.woff2') format('woff2'),
       url('/assets/fonts/magnetic-pro-thin-italic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Eight-bit';
  src: url('/assets/fonts/eightbitoperatorplus8-bold-webfont.woff2') format('woff2'),
       url('/assets/fonts/eightbitoperatorplus8-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Eight-bit';
  src: url('/assets/fonts/eightbitoperatorplus-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/eightbitoperatorplus-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Base font stacks
$base-font: 'Magnetic Pro', 'HeiTi SC Light', 'Microsoft YaHei', sans-serif;
$header-font: 'Magnetic Pro Black', 'Arial Black', sans-serif;
$display-font: 'Eight-bit', 'Lucida Console', 'Lucida Sans Typewriter', monospace;
$special-font: 'Magnetic Pro Thin', sans-serif;

// Colours
$colors: (
  'tuna': rgba(53, 53, 66, 1),
  'black-alpha2': rgba(0, 0, 0, 0.2),
  'english-walnut': rgba(55, 40, 34, 1),
  'winter-hazel': rgba(210, 203, 148, 1),
  'apple': rgba(74, 188, 65, 1),
  'japanese-laurel': rgba(10, 105, 6, 1),
  'sugarcane': rgba(250, 255, 250, 1),
  'mineshaft': rgba(42, 42, 42, 1),
  'royal-blue': rgba(76, 131, 225, 1),
  'silver-chalice': rgba(166, 166, 166, 0.5),
  'white': rgba(255, 255, 255, 1)
);
