// ====
// Base
// ====
h1 {
  font-size: 185%;
  margin-block-end: 1rem;
  line-height: 1.2;
}

h2 {
  font-size: 150%;
  margin-block-end: 0.5rem;
  line-height: 1.2;

  small {
    font-size: 0.5em;
    font-weight: 400;
    text-align: right;
  }
}

h3 {
  font-size: 125%;
  margin-block-end: 0.5rem;
}

h4 {
  font-size: 105%;
  margin-block-end: 0.25rem;
}

p {
  margin-block-end: 1rem;
}

a {
  text-decoration: none;
  color: color("japanese-laurel");
  transition: all 0.15s linear;
  border-block-end: 1px dashed color("japanese-laurel");

  &:hover {
    color: darken(color("japanese-laurel"), 5%);
    border-block-end: 1px solid darken(color("japanese-laurel"), 5%);
  }
}

small {
  font-size: 75%;
}

ul {
  list-style: disc;
  margin-inline-start: 1em;

  ul {
    list-style: square;
  }
}

li {
  margin-block-end: 1em;
}

ol {
  counter-reset: list;
  margin-inline-start: 2em;

  li {
    list-style: none;
    margin-block-end: 1em;
    position: relative;

    &::before {
      content: counter(list) ".";
      counter-increment: list;
      font-weight: bold;
      left: -1.5em;
      position: absolute;
      top: 0;
    }
  }

  ul {
    li {
      list-style: disc;
    }

    li::before {
      content: "";
      counter-increment: none;
    }
  }
}

pre {
  color: #ccc;
  background-color: #2d2d2d;
  padding: 0.5em;
  margin: 0.5em 0 1em;
  border-radius: 0.3em;
  overflow: auto;
  display: flex;
}

code {
  background-color: lighten(color("silver-chalice"), 10%);
  color: color("royal-blue");
  border-radius: 0.25em;
  padding: 2px;
}

pre code {
  font-size: 75%;
  color: #ccc;
  background-color: #2d2d2d;
}

blockquote {
  background: url("/assets/images/bg-quotes.jpg");
  border-inline-start: 0.5em solid color("royal-blue");
  padding: 1em;
  margin-block-end: 1em;
  font-style: italic;

  &::before {
    font-size: 2.5em;
    line-height: 0.1em;
    margin-inline-end: 0.1em;
    vertical-align: -0.25em;
    color: color("royal-blue");
    content: "\201C";
    font-family: $display-font;
  }

  &::after {
    font-size: 2.5em;
    line-height: 0.1em;
    vertical-align: -0.25em;
    color: color("royal-blue");
    content: "\201D";
    font-family: $display-font;
  }

  p {
    line-height: 1.7rem;
  }

  p:first-child,
  p:last-child {
    display: inline;
  }

  p:nth-child(2) {
    margin-block-start: 1em;
  }

  p ~ p {
    display: block;
  }

  a {
    color: inherit;
    border-block-end: 1px dashed color(mine-shaft);
  }
}

figcaption {
  text-align: center;
  font-style: italic;
  margin-block-end: 0.25em;
}

figcaption:lang(zh) {
  font-style: normal;
}

kbd {
  font-size: 70%;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 1px 0 color("black-alpha2"), 0 0 0 2px color("white") inset;
  display: inline-block;
  vertical-align: top;
  margin: 0 0.1em;
  padding: 0.1em 0.6em 0;
  text-shadow: 0 1px 0 color("white");
}

iframe,
picture img,
video {
  margin-block-end: 1em;
}
