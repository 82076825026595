// ======
// Trumps
// ======
.no-margin {
  margin-block-end: 0;
}

a.no-underline,
a.no-underline:hover {
  border: 0;
}

.no-list-style {
  list-style: none;
}

.no-break {
  white-space: nowrap;
}

.break {
  word-break: break-all;
}

.italicise {
  font-style: italic;
}

.sr-only {
  position: absolute;
  opacity: 0;
}
